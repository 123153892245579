import React from "react";
import Home from "./Home";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import Navbarr from "./Navbarr";
import ScrollToTop from "./ScrollToTop";
import Register from "./Register";
import SignIn from "./SignIn";
import CallCenterRegistration from "./CallCenterRegistration";
import { loadStripe } from "@stripe/stripe-js"; 
import { Elements } from "@stripe/react-stripe-js"; 
import SubmitInquiry from "./SubmitInquiry";
import BuildVa from "./BuildVa";
import Blogs from "./Blogs";
import Pricing from "./Pricing";
import About from "./About";
import Account from "./Account";
import ListUpload from "./ListUpload";
import ListHistory from "./ListHistory";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import NotFound from "./NotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./App.css";
import PaymentHistory from "./PaymentHistory";
import ContactPricing from "./ContactPricing";
import Policy from "./Policy";
import Terms from "./Terms";

function App() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)


  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Root />}>
          <Route index element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/CallCenterRegistration" element={<CallCenterRegistration />} />
          <Route path="/SubmitInquiry" element={<SubmitInquiry />} />
          <Route path="/build-va-team" element={<BuildVa />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/ListUpload" element={<ListUpload />} />
          <Route path="/skiptracing" element={<ListHistory />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/PaymentHistory" element={<PaymentHistory />} />
          <Route path="/ContactPricing" element={<ContactPricing />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />

          
          
          <Route path="*" element={<NotFound />} />

        </Route>
      </>
    )
  );

  return (
    <Elements stripe={stripePromise}>
    <div className={`app`}>
      <RouterProvider router={router}></RouterProvider>
    </div>
    </Elements>
  );
}

export default App;

const Root = () => {


  return (
    <div className={`App`}>
      <ScrollToTop />

      <Navbarr/>
      <Outlet />
    </div>
  );
};
